import React from 'react';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import Tile from './components/Tile';
import Tiles from './components/Tiles';
import { TileContext } from './hooks/tileHook';
import { Divider } from '@audi/audi-ui-react-v2';
import ResultsBar from './components/ResultsBar';
import { useServicesContext } from '@oneaudi/vtp-shared';
import ZeroResultsPage from './components/ZeroResultsPage';
import { CombinedConfig } from './FeatureHubAppDefinition';

interface Props {}

const Favorites: React.FC<Props> = () => {
  const vehicles = useSelector((state: unknown) =>
    SELECTORS.VEHICLES.getFavoriteVehicleIdsState(state)
  ) as string[];

  const configuration = useServicesContext().featureAppConfig as CombinedConfig;
  const newSearchUrl =
    configuration?.urls?.newSearchUrl !== undefined ? configuration?.urls?.newSearchUrl : '';

  return (
    <TileContext.Provider
      value={{
        viewType: 'list',
        setViewType: () => {},
        appContext: 'favorites',
        contentConfiguration: configuration,
      }}
    >
      <ResultsBar />
      <Divider spaceStackStart="l" />
      {vehicles.length === 0 ? (
        <ZeroResultsPage context="favorites" newSearchUrl={newSearchUrl} />
      ) : (
        <>
          <Tiles>
            {vehicles.map((vehicleId) => (
              <Tile key={vehicleId} vehicleId={vehicleId} />
            ))}
          </Tiles>
        </>
      )}
    </TileContext.Provider>
  );
};

export default Favorites;
