import React, { useRef } from 'react';
import { Text, Button, PopoverTrigger, PopoverBody } from '@audi/audi-ui-react-v2';
import { audiIcons, Icon, DangerousElement } from '@oneaudi/vtp-shared';
import { useI18n } from '@oneaudi/i18n-context';
import { DistinctiveContainer, PollutionBadge, StyledPopover } from '../Tile.styles';

export interface DistinctiveTabProps {
  assetUrl: string;
  pollutionBadge: string | undefined;
}

const DistinctiveDGT: React.FC<DistinctiveTabProps> = ({ assetUrl, pollutionBadge }) => {
  const distinctiveTooltip = useI18n({
    id: `nemo.ui.sc.result.tile.${pollutionBadge}`,
    defaultMessage: '',
  });
  const pollutionBadgeAlt = useI18n({
    id: 'nemo.ui.sc.details.technical-data.pollution-badge.label',
    defaultMessage: '',
  });
  const targetRef = useRef<HTMLElement>(null);
  return (
    <DistinctiveContainer>
      <Text variant="copy2" as="span" spaceInlineStart="m" spaceStackStart="xxs">
        <PollutionBadge src={assetUrl} data-testid="pollutionBadge" alt={pollutionBadgeAlt} />
      </Text>
      <PopoverTrigger placement="right" targetRef={targetRef}>
        {(triggerProps: any) => (
          <>
            <Button
              spaceInlineStart="s"
              variant="text"
              data-testid="pollutionBadge-info-icon"
              {...triggerProps}
              ref={targetRef}
              icon={<Icon icon={audiIcons.systemInfoSmall} />}
            />
            <StyledPopover data-testid="tooltipContainer" aria-label={distinctiveTooltip}>
              <PopoverBody>
                <Text data-testid="tooltipContent">
                  <DangerousElement sanitize html={distinctiveTooltip} />
                </Text>
              </PopoverBody>
            </StyledPopover>
          </>
        )}
      </PopoverTrigger>
    </DistinctiveContainer>
  );
};

export default DistinctiveDGT;
