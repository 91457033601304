import * as React from 'react';
import styled from 'styled-components';
import {
  DangerousElement,
  CodeDescription,
  audiIcons,
  Icon,
  warrantyCodeLookup,
  warrantyImageTypes,
  useWarrantyLabel,
} from '@oneaudi/vtp-shared';
import { LayoutItem, Text } from '@audi/audi-ui-react-v2';
import { COLOR_BASE_BRAND_PROGRESSIVE_RED } from '@audi/audi-ui-design-tokens';
import WarrantyImg from './WarrantyImg';
import { WarrantyTechDataItem } from '@oneaudi/vtp-configuration-service';

interface WarrantyTypeProps {
  item: WarrantyTechDataItem;
  warranties: CodeDescription[] | undefined;
  isDesktop: boolean;
}
const hasWarranty = (warranties: CodeDescription[] = [], type: string): boolean => {
  return (
    warranties?.filter(
      (warranty: CodeDescription) =>
        (warrantyCodeLookup as Record<string, string>)[type] === warranty.code
    ).length > 0
  );
};

const WarrantyTypeWarranty: React.FC<WarrantyTypeProps> = ({ item, warranties, isDesktop }) => {
  const { infoLayerLink, techDataKey } = item;
  const showWarrantyImage = warrantyImageTypes.includes(techDataKey);

  // Ignore plus type warranty for former warranties
  const warrantiesExceptPlus = warranties?.filter(
    (warranty) => warranty.code !== 'used-car-warranty-type.plus'
  );
  const label = useWarrantyLabel(item.techDataKey, item.iconLabel);
  const isDesktopModeWithWarrantyImage = isDesktop && showWarrantyImage;
  const isDesktopModeWithNoWarrantyImage = isDesktop && !showWarrantyImage;
  return (
    <>
      {hasWarranty(warrantiesExceptPlus, techDataKey) && (
        <FlexContainer>
          <WarrantyItemContainer data-tech-key={techDataKey}>
            {!isDesktop && (
              <Text
                as="p"
                variant="copy2"
                spaceStackEnd="xs"
                spaceInlineStart="s"
                data-test-id="warrantyLabel"
              >
                <DangerousElement html={label} as="span" />
              </Text>
            )}
            {isDesktopModeWithWarrantyImage && (
              <WarrantyImg type={techDataKey} data-test-id="warrantyImage" isSeal={false} />
            )}
            {isDesktopModeWithNoWarrantyImage && (
              <>
                <Text
                  as="span"
                  variant="copy2"
                  spaceStackEnd="xs"
                  spaceInlineEnd="s"
                  spaceInlineStart="s"
                  data-test-id="warrantyDescription"
                >
                  <DangerousElement html={label} as="span" />
                </Text>
              </>
            )}
            {infoLayerLink !== undefined && (
              <InfoIcon
                className="nm-layerLink"
                href={infoLayerLink}
                x-cq-linkchecker="skip"
                data-test-id="warrantyInfoLink"
              >
                <Icon icon={audiIcons.systemInfoSmall} />
              </InfoIcon>
            )}
          </WarrantyItemContainer>
        </FlexContainer>
      )}
    </>
  );
};
const InfoIcon = styled.a`
  display: inline-block;
  height: 24px;
  margin-left: var(${(props): string => props.theme.responsive.spacing.xs});
  width: 24px;
`;

const WarrantyItemContainer = styled(LayoutItem)`
  display: flex;
  align-items: flex-start;
  .sc-audi-red-plus {
    color: ${COLOR_BASE_BRAND_PROGRESSIVE_RED};
    font-weight: ${(props): string => props.theme.fontWeights.bold};
  }
`;

const FlexContainer = styled.div`
  display: block;
  @media (min-width: 560px) {
    display: inline-flex;
    flex-wrap: wrap;
  }
`;

export default WarrantyTypeWarranty;
