import {FeatureServiceBinding} from '@feature-hub/core';
import {FeatureServiceImplementation} from '@volkswagen-onehub/sonic-feature-hub-tools';
import {ACTIONS, SELECTORS, StockcarsStoreClass } from '@oneaudi/stck-store';

import {AudiStockcarsStoreServiceOptions} from '../audi-stockcars-store-service';

export interface AudiStockcarsStoreServiceV1 {
    selectors: typeof SELECTORS;
    store: StockcarsStoreClass;
    actions: typeof ACTIONS;
}

export const AudiStockcarsStoreServiceV1Implementation: FeatureServiceImplementation<AudiStockcarsStoreServiceV1, AudiStockcarsStoreServiceOptions> = (options) => {
  const store = StockcarsStoreClass.getInstance(options.marketPath || '', options.scsStartPage || '');
  return {
    create: (): FeatureServiceBinding<AudiStockcarsStoreServiceV1> => {
      return {
        featureService: {
          selectors: SELECTORS,
          store: store,
          actions: ACTIONS,
        },
      };
    },
  };
};
