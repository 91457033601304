import { Accordion, AccordionSection } from '@audi/audi-ui-react-v2';
import React from 'react';

type SuggestedMobileWrapperProps = React.PropsWithChildren & {
  isMobile: boolean;
};
const SuggestedMobileWrapper: React.FC<SuggestedMobileWrapperProps> = ({ children, isMobile }) => {
  return isMobile ? (
    <Accordion>
      <AccordionSection headingLevel="h2" id="section" label="Empfohlene Fahrzeuge">
        {children}
      </AccordionSection>
    </Accordion>
  ) : (
    <>{children}</>
  );
};
export default SuggestedMobileWrapper;
