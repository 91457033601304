import {
  useServicesContext,
  CodeDescription,
  VehicleBasic,
  DangerousElement,
  useDesktopOrMobileView,
  Icon,
  audiIcons,
} from '@oneaudi/vtp-shared';
import React from 'react';
import TileFormerWarranty from './WarrantyTypeWarranty';
import { Text } from '@audi/audi-ui-react-v2';
import { Warranties } from '@oneaudi/fa-vtp-warranty';
import WarrantyImg from './WarrantyImg';
import { useI18n } from '@oneaudi/i18n-context';
import {
  PlusSealContainer,
  SealTextAndIconDiv,
  WarrantiesContainer,
  WarrantyInfoIconBtn,
} from '../../Tile.styles';
import { isListView } from '../../../hooks/tileHook';
import { FocusLayerSizeV2, LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils';
import { CombinedConfig } from '../../../FeatureHubAppDefinition';
import { WarrantyTechDataItem } from '@oneaudi/vtp-configuration-service';

interface Props {
  version: string;
  vehicle: VehicleBasic;
  hasFAWarranties: number | false | undefined;
  hasFormerWarranties: false | 0 | CodeDescription[] | undefined;
  hasWarrantyPlus: boolean;
}

const TileWarranties: React.FC<Props> = ({
  version,
  vehicle,
  hasFAWarranties,
  hasFormerWarranties,
  hasWarrantyPlus,
}) => {
  const { getConfiguration } = useServicesContext();
  const contextRawData = getConfiguration()?.warrantyTechDataItems;
  // clean duplicate 'TechdataKey' entries
  const contextRawDataArray: WarrantyTechDataItem[] = [
    ...new Map(contextRawData?.map((item) => [item.techDataKey, item])).values(),
  ];
  const warrantiesToShow = contextRawDataArray.filter(
    (item: WarrantyTechDataItem) =>
      item?.techDataKey !== undefined &&
      item?.techDataKey.includes('Warranty') &&
      !item.techDataKey.includes('Expiry')
  );
  const plusLbl = useI18n({ id: 'nemo.sc.icon.legend.WarrantyTypePlus' });
  const isDesktop: boolean = useDesktopOrMobileView('561px') === 'desktop';
  const configuration = useServicesContext().featureAppConfig as CombinedConfig;
  const warrantyInfoUrl = configuration?.tile.warrantyInfoUrl;
  const layerManager = useServicesContext().getAdditionalService(
    'gfa:layer-manager'
  ) as LayerManagerV27;

  const openWarrantyLayer = () => {
    layerManager?.openFocusLayer(
      () => <LayerContentHTML url={warrantyInfoUrl!.replace(/\.html$/, '.headless.html')} />,
      {},
      {
        size: FocusLayerSizeV2.A,
        userCloseable: true,
      }
    );
  };
  return (
    <WarrantiesContainer data-testid="warrantiesContainer">
      {hasFAWarranties && (
        <Warranties
          vehicleId={vehicle.id}
          compactDisplay
          isListView={isListView()}
          assetUrl={`https://fa-vtp-warranty.cdn.prod.arcade.apps.one.audi/${version}/static`}
        />
      )}
      {hasFAWarranties === undefined && hasFormerWarranties && warrantiesToShow.length > 0 && (
        <>
          {warrantiesToShow.map((item: WarrantyTechDataItem) => {
            return (
              <TileFormerWarranty
                warranties={vehicle?.used?.warrantyTypes}
                item={item}
                isDesktop={isDesktop}
                key={item.techDataKey}
              />
            );
          })}
        </>
      )}
      {hasWarrantyPlus && (
        <PlusSealContainer data-testid="plusSeal">
          {isDesktop && <WarrantyImg isSeal type="" data-test-id="warrantySeal" />}
          {!isDesktop && (
            <SealTextAndIconDiv>
              <Text
                as="p"
                variant="copy2"
                spaceStackEnd="xs"
                spaceInlineStart="s"
                data-test-id="warrantyLabel"
              >
                <DangerousElement html={plusLbl} as="span" />
              </Text>
              <WarrantyInfoIconBtn
                variant="text"
                data-testid="warrantyInfoIcon_ext"
                onClick={openWarrantyLayer}
                icon={<Icon icon={audiIcons.systemInfoSmall} />}
              />
            </SealTextAndIconDiv>
          )}
        </PlusSealContainer>
      )}
    </WarrantiesContainer>
  );
};
export default TileWarranties;
