import {FeatureServiceBinder, SharedFeatureService} from '@feature-hub/core';
import {defineFeatureService, FeatureServiceDefinition} from '@volkswagen-onehub/sonic-feature-hub-tools';

import {AudiStockcarsStoreServiceV1, AudiStockcarsStoreServiceV1Implementation} from './v1/audi-stockcars-store-service-v1';

interface Service extends SharedFeatureService {
  readonly '1.0.0': FeatureServiceBinder<AudiStockcarsStoreServiceV1>;
}

interface AudiStockcarsStoreServiceOptions {
  marketPath: string;
  scsStartPage: string;
}

const definition: FeatureServiceDefinition<AudiStockcarsStoreServiceV1, AudiStockcarsStoreServiceOptions> = {
  id: 'audi-stockcars-store-service',
  versions: {
    '1.0.0': AudiStockcarsStoreServiceV1Implementation,
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const defineAudiStockcarsStoreService: any = defineFeatureService<
  AudiStockcarsStoreServiceV1,
  Service,
  AudiStockcarsStoreServiceOptions
>(definition);

export {defineAudiStockcarsStoreService, AudiStockcarsStoreServiceV1, AudiStockcarsStoreServiceOptions};
