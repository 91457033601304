import React from 'react';
import { ProviderProps as AppProps } from '@oneaudi/oneaudi-os-react';
import { useServicesContext } from '@oneaudi/vtp-shared';
import Results from './Results';
import Favorites from './Favorites';
import { trackFeatureAppReady } from './components/tracking';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { CombinedConfig } from './FeatureHubAppDefinition';
import { FavoritesSkeleton } from './components/Skeleton/FavoritesSkeleton';
import { ResultsSkeleton } from './components/Skeleton/ResultsSkeleton';
import Suggested from './Suggested';
import { TilesContainer } from './FeatureApp.styles';
import { ConfigurationServiceV1 } from '@oneaudi/vtp-configuration-service';

interface FeatureAppProps extends AppProps {}
const FeatureApp: React.FC<FeatureAppProps> = () => {
  const configurationTiles = useServicesContext().featureAppConfig as CombinedConfig;
  const configurationFA = useServicesContext().getAdditionalService(
    'vtp-configuration-service'
  ) as ConfigurationServiceV1;
  const sortingConfiguration =
    configurationFA?.getConfiguration()?.sortParams || configurationTiles?.sortParams;

  const appContext = configurationTiles?.appContext || 'results';
  const viewType = configurationTiles?.viewType;
  const useNewFilterMargins = configurationTiles?.useNewFilterMargins;
  /* eslint-disable no-nested-ternary */
  const sortParam = sortingConfiguration?.defaultOption
    ? sortingConfiguration?.defaultOption
    : sortingConfiguration?.options?.length
    ? sortingConfiguration?.options[0]
    : 'prices.retail:asc';
  /* eslint-disable no-nested-ternary */

  const [isClient, setIsClient] = React.useState(false);

  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  React.useEffect(() => {
    trackFeatureAppReady(trackingService, viewType!, sortParam);
    setIsClient(true);
  }, []);

  let component;
  let ssrComponent;
  switch (appContext) {
    case 'favorites':
      component = <Favorites />;
      ssrComponent = <FavoritesSkeleton />;
      break;
    case 'results':
      component = <Results />;
      ssrComponent = <ResultsSkeleton />;
      break;
    case 'suggested':
    default:
      component = <Suggested />;
      ssrComponent = <></>;
      break;
  }

  return useNewFilterMargins ? (
    <TilesContainer>{isClient ? component : ssrComponent}</TilesContainer>
  ) : (
    <>{isClient ? component : ssrComponent}</>
  );
};

export default FeatureApp;
