import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { ProductData } from '@oneaudi/vtp-shared';

const getEventName = (ctaType: string): string => {
  let eventName = '';
  switch (ctaType) {
    case 'contact':
    case 'nws':
      eventName = 'vtp results - click on contact';
      break;
    case 'ecom':
      eventName = 'vtp results - click on oneaudi shop';
      break;
    case 'phone':
      eventName = 'vtp results - call dealer';
      break;
    default:
      eventName = 'vtp results - click on generic cta';
  }
  return eventName;
};

export const trackImageChange = (
  trackingService: TrackingServiceV2,
  elementName: 'arrow' | 'image' | 'other',
  label: string,
  pos: string,
  relatedProduct: ProductData,
  actionType: 'swipe' | 'click' | 'automatic'
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'navigation',
          eventName: 'vtp results - change gallery image',
        },
        attributes: {
          componentName: 'vtp-results',
          actionType,
          label,
          targetURL: '',
          clickID: '',
          elementName,
          value: '',
          pos,
          relatedProduct,
        },
      },
    });
  }
};

export const trackViewChange = (
  trackingService: TrackingServiceV2,
  viewType: 'list' | 'grid',
  label: string
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: viewType === 'list' ? 'vtp results - list view' : 'vtp results - grid view',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: '',
        },
      },
      componentUpdate: {
        attributes: {
          viewType,
        },
      },
    });
  }
};

export const trackSorting = (trackingService: TrackingServiceV2, label: string, value: string) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: 'vtp results - sort',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName: 'drop down',
          value,
          pos: '',
        },
      },
      componentUpdate: {
        attributes: {
          sortingOption: value,
        },
      },
    });
  }
};

export const trackDetailsPageNav = (
  trackingService: TrackingServiceV2,
  label: string | any,
  targetURL: string,
  elementName: string
) => {
  const completeURL = `${window.location.origin}${targetURL}`;
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'internal_link',
          eventName: 'vtp results - go to detail page',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: completeURL,
          clickID: '',
          elementName,
          value: '',
          pos: '',
        },
      },
    });
  }
};

export const trackAddToFav = (
  trackingService: TrackingServiceV2,
  label: string,
  relatedProduct: ProductData
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'favorite',
          eventName: 'vtp results - add to favorites',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName: 'icon',
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};

export const trackRemoveFromFav = (
  trackingService: TrackingServiceV2,
  label: string,
  relatedProduct: ProductData
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'favorite',
          eventName: 'vtp results - remove from favorites',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName: 'icon',
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};

export const trackTabChange = (
  trackingService: TrackingServiceV2,
  label: string,
  relatedProduct: ProductData
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: 'vtp results - tab click',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};
const getEcomEventAction = (targetURL: string) =>
  targetURL.startsWith(window.location.origin) ? 'internal_link' : 'exit_link';
export const trackCTAClick = (
  trackingService: TrackingServiceV2,
  eventAction: 'call' | 'internal_link' | 'exit_link',
  type: string,
  label: string,
  targetURL: string,
  relatedProduct: ProductData
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: type === 'ecom' ? getEcomEventAction(targetURL) : eventAction,
          eventName: getEventName(type),
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL,
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};

export const trackLayerLinkClick = (
  trackingService: TrackingServiceV2,
  label: string,
  elementName: 'text link' | 'icon',
  relatedProduct: ProductData
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'internal_link',
          eventName: 'vtp results - open modal layer ',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName,
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};
export const trackLayerOpen = (
  trackingService: TrackingServiceV2,
  eventName: string,
  pageID?: string,
  promotionName?: string
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'view_change',
          eventName,
        },
        attributes: {
          componentName: 'vtp-results',
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        },
      },
      componentUpdate: {
        page: {
          pageInfo: {
            pageID,
          },
          attributes: {
            promotionName,
          },
        },
      },
    });
  }
};
export const trackLayerClose = (trackingService: TrackingServiceV2, value: string) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: 'vtp results - close modal layer',
        },
        attributes: {
          componentName: 'vtp-results',
          label: '',
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value,
          pos: '',
        },
      },
      componentUpdate: {
        page: {
          pageInfo: {
            pageID: '',
          },
          attributes: {
            promotionName: '',
          },
        },
      },
    });
  }
};

export const trackFeatureAppReady = (
  trackingService: TrackingServiceV2,
  viewType: 'list' | 'grid',
  sortingOption: string
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: 'vtp results - feature app ready',
        },
        attributes: {
          componentName: 'vtp-results',
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        },
      },
      componentUpdate: {
        attributes: {
          implementer: 2,
          viewType,
          sortingOption,
        },
      },
    });
  }
};
