import * as React from 'react';
import { Text } from '@audi/audi-ui-react-v2';

import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';

interface PriceSectionProps {
  vehicleOrderStatus: string;
}

const VehicleOrderStatusWrap = styled.div`
  margin-top: var(${(props): string => props.theme.responsive.spacing.s});
`;

const IN_DELIVERY_STATES = ['7', '8', '9', '10'];
const AT_DEALER_STATES = ['11', '12'];

// eslint-disable-next-line no-shadow
export enum DeliveryStates {
  // eslint-disable-next-line no-unused-vars
  IN_DELIVERY = 'in-delivery',
  // eslint-disable-next-line no-unused-vars
  AT_DEALER = 'at-dealer',
}

export const hasOrderStatus = (orderStatus: string): boolean => {
  if (!orderStatus) {
    return false;
  }
  const validType = !Number.isNaN(parseInt(orderStatus, 10));
  const validState =
    IN_DELIVERY_STATES.includes(orderStatus) || AT_DEALER_STATES.includes(orderStatus);
  return validType && validState;
};

export const getOrderStatusTextKey = (orderStatus: string): string => {
  if (IN_DELIVERY_STATES.includes(orderStatus)) {
    return DeliveryStates.IN_DELIVERY;
  }

  if (AT_DEALER_STATES.includes(orderStatus)) {
    return DeliveryStates.AT_DEALER;
  }

  return '';
};

export const VehicleOrderStatus: React.FC<PriceSectionProps> = ({ vehicleOrderStatus }) => {
  if (!hasOrderStatus(vehicleOrderStatus)) {
    return null;
  }

  const orderStatusTextKey = getOrderStatusTextKey(vehicleOrderStatus);
  const orderStatusLabel = useI18n({ id: 'nemo.ui.sc.details.dealer.order-status.label' });
  const orderStatus = hasOrderStatus(vehicleOrderStatus)
    ? useI18n({
        id:
          orderStatusTextKey === 'in-delivery'
            ? 'nemo.ui.sc.details.dealer.order-status.in-delivery'
            : 'nemo.ui.sc.details.dealer.order-status.at-dealer',
      })
    : '';

  return (
    <VehicleOrderStatusWrap>
      <Text variant="copy1" weight="bold" as="span">
        {orderStatusLabel}:{' '}
      </Text>
      <Text variant="copy1" as="span">
        {orderStatus}
      </Text>
    </VehicleOrderStatusWrap>
  );
};
